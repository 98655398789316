import './App.css';
import profilePic from './img/profile-pic-cropped.jpg';
import instaLogo from './img/insta.png';
import mvLogo from './img/manyvids.png';
import {ReactComponent as OnlyFansSVG} from "./img/onlyfans-logo-vector.svg";
import {ReactComponent as FanslySVG} from "./img/fansly.svg";
import {ReactComponent as TwitterSVG} from "./img/twitter.svg";
import {ReactComponent as TikTokSVG} from "./img/tiktok.svg";
import {ReactComponent as TwitchSVG} from "./img/twitch.svg";
import ReactGA from 'react-ga';
const TRACKING_ID = 'G-LXPJJ0S5DF';
ReactGA.initialize(TRACKING_ID);

function App() {
    return (
        <div className="App">
            <div className="Container--main">
                <div className="Container-profile">
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_onlyfans"
                        to="https://onlyfans.com/evalord/c11"
                    >
                        <div className="Container--profile-flex">
                            <img className={"Image--profile-picture"} src={profilePic} alt={"Profile"}/>
                        </div>
                    </ReactGA.OutboundLink>
                </div>
                <div className="Container-links">
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_onlyfans"
                        to="https://onlyfans.com/evalord/c11"
                    >
                        <div className="Container--profile-of-link Bubble--social">
                            <OnlyFansSVG className="Logo--of"/>
                            <span className="Link--of">FREE chat with me 😈</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_manyvids"
                        to="https://evalord.manyvids.com"
                    >
                        <div className="Container--profile-of-link Bubble--social">
                            <img className={"Logo--mv"} src={mvLogo} alt=""/>
                            <span className="Link--of">Exclusive Content 🎥</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_fansly"
                        to="https://fansly.com/EvaLord"
                    >
                        <div className="Container--profile-of-link Bubble--social">
                            <FanslySVG className="Logo--fansly"/>
                            <span className="Link--of">FREE Exclusive Page 🌶️</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_twitter"
                        to="http://www.twitter.com/evalordofficial"
                    >
                        <div className="Container--profile-of-link Bubble--social">

                            <TwitterSVG className="Logo--twitter"/>
                            <span className="Link--of">Twitter (18+)</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_instagram"
                        to="http://www.instagram.com/evalordofficial"
                    >
                        <div className="Container--profile-of-link Bubble--social">
                            <img className={"Logo--insta"} src={instaLogo} alt=""/>
                            <span className="Link--of">Instagram</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_tiktok"
                        to="https://www.tiktok.com/@evalordofficial"
                    >
                        <div className="Container--profile-of-link Bubble--social">
                            <TikTokSVG className="Logo--tiktok"/>
                            <span className="Link--of">TikTok</span>
                        </div>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        className="App-link"
                        eventLabel="social_click_twitch"
                        to="https://www.twitch.tv/evalordofficial"
                    >
                        <div className="Container--profile-of-link Bubble--social">

                            <TwitchSVG className="Logo--twitch"/>
                            <span className="Link--of">Twitch</span>
                        </div>
                    </ReactGA.OutboundLink>
                </div>
            </div>
        </div>
    );
}

export default App;
